import React from "react"
import { Link } from 'gatsby'
import Image from '../components/image-provider'

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ArrowRightSVG, ArrowLeftSVG } from '../components/svgs'

const NotFoundPage = () => {

    return(
        <Layout>
            <SEO
                title="404: Not found"
            />
            <div>
                <div className="max-viewport mx-auto pt-16 pb-12 laptop:pt-24">
                    <h2 className="text-center text-sm laptop:text-lg opacity-70">These are not the droids you are looking for.</h2>
                    <Image
                        fileName="obiwankenobi404.png"
                        className="mx-auto mt-8"
                        alt="Digital illustration of Obi-Wan Kenobi clasping his hands together. Large number 404 in background."
                        style={{ maxWidth: '768px' }}
                    />
                    <div className="flex flex-wrap text-white laptop:mt-8">
                        <div className="w-full flex laptop:w-1/3">
                            <Link
                                to="/"
                                className="bg-magenta rounded-md py-2 w-full mx-auto mt-8 flex justify-around"
                                style={{ maxWidth: '264px' }}
                            >
                                <div className="flex items-center">
                                <ArrowLeftSVG className="fill-current text-white mr-1" />
                                <p className="mr-4">Move Along</p>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full flex laptop:w-1/3">
                            <Link
                                to="/services/"
                                className="bg-magenta rounded-md py-2 w-full text-center mx-auto mt-8"
                                style={{ maxWidth: '264px' }}
                            >
                                Go about your business
                            </Link>
                        </div>
                        <div className="w-full flex laptop:w-1/3">
                            <Link
                                to="/contact/"
                                className="bg-magenta rounded-md py-2 w-full mx-auto mt-8 flex justify-around"
                                style={{ maxWidth: '264px' }}
                            >
                                <div className="flex items-center">
                                <p className="ml-4">Report to the Emperor</p>
                                <ArrowRightSVG className="fill-current text-white ml-1" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage;
